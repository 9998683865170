import { get, getSSR } from "@/redux/module/api";
import { NoticeNodePropsType } from "@/types/PropsTypes";
import React, { Component } from "react";
import NoticeNode from "./NoticeNode";

export async function getserverSideProps(context: any) {
  const cookies = context.req.headers.cookie || "";
  const res = await getSSR("notice", {}, cookies);

  let resData = null;
  try {
    resData = res.status == 200 ? res.data.resData : null;
  } catch (e) {
    console.log(e);
  }

  return {
    props: {
      resData: resData,
    },
  };
}

class NoticeList extends Component<{}, { resData: NoticeNodePropsType[] }> {
  constructor(props: any) {
    super(props);
    this.state = {
      resData: [],
    };
  }

  componentDidMount = async () => {
    const res = await get("notice", {});
    let resData = null;
    try {
      resData = res.status == 200 ? res.data.resData : null;
    } catch (e) {
      console.log(e);
    }

    this.setState({
      resData: resData,
    });
  };

  render() {
    return (
      this.state.resData &&
      this.state.resData.map((data: NoticeNodePropsType, index: number) => {
        return <NoticeNode key={data.id} resData={data} index={index} />;
      })
    );
  }
}

export default NoticeList;
