import React, { Component } from 'react';
import HorizontalSlide from '../molecules/HorizontalSlide';
import { FestivalNodePropsType } from '@/types/PropsTypes';
import FestivalNode from './FestivalNode';

interface Props {
  festivalList: FestivalNodePropsType[];
}

class FestivalList extends Component  <{festivalList: FestivalNodePropsType[];}> {
    render() {
        const {
            festivalList,
        } = this.props;
        return (
            <HorizontalSlide
            padding={"0"}
              childrens={
                this.props.festivalList &&
                this.props.festivalList.map((node, index) => {
                  return (
                    index < 10 && (
                     
            <FestivalNode
            width='30vw'
            height='40vw'
            id={node.id}
            startDate={node.startDate}
            endDate={node.endDate}
            title={node.title}
            subTitle={node.subTitle}
            // locationInfo={node.locationInfo}
            animeTitle={node.animeTitle}
            animeId={node.animeId}
            productCategoryName={node.productCategoryName}
            productCategoryId={node.productCategoryId}
            color={node.color}
            compactThumbnailImgUrl={node.compactThumbnailImgUrl}
            link={node.link}   
          />
                    )
                  );
                })
              }
            />
        );
    }
}

export default FestivalList; 