import { ProductListPropsType } from "@/types/ProductTypes";
import { ListPropsType } from "@/types/PropsTypes";
import React, { Component } from "react";
import ProductRowListNode from "./ProductRowListNode";
import {
  addRecentlyViewedProduct,
  getDetailImgList,
  isLoginUser,
  saveScrollPosition,
} from "@/Utils/Utils";
import { get } from "@/redux/module/api";

class ProductRowList extends Component<
  ListPropsType & {
    isAutoLoad: boolean;
    url: string;
  },
  {
    resData: ProductListPropsType[];
    drawerOpen: boolean;
    selectedProductId: number;
    isLogin: boolean;
    isLoading: boolean;
    pageNumber: number;
  }
> {
  PAGE_SIZE = 20;
  loadingElementRef: React.RefObject<HTMLDivElement>;

  observer: IntersectionObserver | null = null;

  constructor(props: any) {
    super(props);

    this.state = {
      resData: [],
      isLoading: true,

      drawerOpen: false,
      selectedProductId: 0,
      pageNumber: 0,

      isLogin: false,
    };
    this.loadingElementRef = React.createRef<HTMLDivElement>();
  }
  handlePopstate = () => {
    // 페이지 뒤로가기 발생 시 처리할 로직을 여기에 구현
    // 예: 추가된 상품 상태를 초기화하거나 다른 동작 수행
  };
  handleIntersection: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[]
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
        // 교차 여부를 확인하는 요소가 loadingElementRef일 때만 로드
        if (entry.target === this.loadingElementRef.current) {
          if (this.props.isAutoLoad) {
            this.fetchMoreProductList();
          }
        }
      }
    });
  };

  async fetchMoreProductList() {
    let params: any = this.getParams(this.state.pageNumber + 1);

    const res = await get(params.url, params);
    const prevResData = this.state.resData;

    if (res.status === 200) {
      if (res.data.resData.length === 0) {
        return;
      }

      this.setState({
        resData: prevResData.concat(res.data.resData as ProductListPropsType[]),
        pageNumber: this.state.pageNumber + 1,
        drawerOpen: false,
        selectedProductId: 0,
      });
    }
    // this.props.fetchListProduct(params, 0);
  }
  async componentDidMount() {
    window.addEventListener("popstate", this.handlePopstate);

    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null, // 스크롤 컨테이너를 사용하려면 해당 요소를 지정
      threshold: 1.0, // 관찰 대상 요소의 100%가 교차하면 콜백 함수 호출
    });

    let params: any = this.getParams(0);

    // this.props.fetchListProduct(params, 0);

    const res = await get(params.url, params);

    this.setState({
      resData: res.data.resData as ProductListPropsType[],
      pageNumber: 0,
      drawerOpen: false,
      selectedProductId: 0,
    });

    // Intersection Observer 시작
    if (this.loadingElementRef.current) {
      this.observer.observe(this.loadingElementRef.current);
    }

    if (this.props.isWishList) {
      this.PAGE_SIZE = 20;

      isLoginUser().then((result) => {
        this.setState((prevState) => ({
          ...prevState,
          isLogin: result,
        }));
      });
    }
  }

  getParams = (pageNumer: number) => {
    let params = {};
    params = {
      url: this.props.url,
      pageNumber: pageNumer,
      pageSize: this.PAGE_SIZE,
    };

    return params;
  };
  onCartClick = (productId: any) => {
    this.setState((prevState) => ({
      ...prevState,
      drawerOpen: true,
      selectedProductId: productId,
    }));
  };

  onClick = (recentProduct: ProductListPropsType) => {
    addRecentlyViewedProduct(recentProduct);
    saveScrollPosition(0);
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          margin: "5px",
        }}
      >
        {this.state.resData ? (
          this.state.resData.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  // margin: "5px",
                  width: "100%",
                }}
              >
                <ProductRowListNode
                  isSales={data.isSales}
                  detailImgUrlList={getDetailImgList(
                    data.compactThumbnailImgUrl,
                    data.detailImgUrlList
                  )}
                  createTime={data.createTime}
                  onCartClick={this.onCartClick}
                  isTradingProduct={data.isTradingProduct}
                  key={data.id}
                  id={data.id}
                  name={data.name}
                  status={data.status}
                  isSoldOut={
                    // data.stock > 0 ? 0 : 1

                    data.isSoldOut
                  }
                  thumbnailImgUrl={data.thumbnailImgUrl}
                  compactThumbnailImgUrl={data.compactThumbnailImgUrl}
                  releaseDate={data.releaseDate}
                  deadlineDate={data.deadlineDate}
                  arrivalDate={data.arrivalDate}
                  originalPrice={data.originalPrice}
                  reservationPrice={data.reservationPrice}
                  arrivalPrice={data.arrivalPrice}
                  isWish={data.isWish}
                  isOptionable={data.isOptionable}
                  animeId={data.animeId}
                  animeName={data.animeName}
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    this.onClick(data)
                  }
                />
              </div>
            );
          })
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            상품이 없습니다.
          </div>
        )}
        <div
          ref={this.loadingElementRef}
          style={{
            height: "100px",
            margin: "10px",
            background: "transparent",
          }}
        >
          {/* 여기에 교차 여부를 감지할 요소(일반적으로 로딩 스피너 또는 힌트 메시지 등)를 추가할 수 있습니다. */}
        </div>
      </div>
    );
  }
}

export default ProductRowList;
