import React, { Component } from "react";
import ImageRowListNode, { ImageNodePropsType } from "./ImageRowListNode";
import { get } from "@/redux/module/api";

class ImageRowList extends Component<
  { url: string; onClick?: any },
  { resData: ImageNodePropsType[] }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      resData: [],
    };
  }
  componentDidMount = async () => {
    let resData = undefined;
    try {
      resData = await get(this.props.url, {});
      if (resData.status === 200) {
        resData = resData.data.resData;
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({
      resData: resData,
    });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {this.state.resData && this.state.resData.length > 0 ? (
          this.state.resData.map((data: ImageNodePropsType, index: number) => {
            return (
              index % 2 === 0 && (
                <div
                  key={data.id}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <ImageRowListNode
                    key={data.id}
                    id={data.id}
                    link={data.link}
                    title={data.title}
                    subTitle={data.subTitle}
                    thumbnailImgUrl={data.thumbnailImgUrl}
                    color={data.color}
                    width={"50%"}
                    onClick={this.props.onClick}
                  />
                  <ImageRowListNode
                    key={this.state.resData[index + 1].id}
                    id={this.state.resData[index + 1].id}
                    link={this.state.resData[index + 1].link}
                    title={this.state.resData[index + 1].title}
                    subTitle={this.state.resData[index + 1].subTitle}
                    thumbnailImgUrl={
                      this.state.resData[index + 1].thumbnailImgUrl
                    }
                    color={this.state.resData[index + 1].color}
                    width={"50%"}
                    onClick={this.props.onClick}
                  />
                </div>
              )
            );
          })
        ) : (
          <div>{/* 데이터가 없습니다. */}</div>
        )}
      </div>
    );
  }
}

export default ImageRowList;
