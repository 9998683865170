import { orangeSecondary, primaryText } from '@/styles/theme';
import { FestivalNodePropsType } from '@/types/PropsTypes';
import { Card, CardActionArea, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import React, { Component } from 'react';
import Label from './Label';
import {
  getRGBAValue,
} from "@/Utils/Utils";

class FestivalNode extends Component<FestivalNodePropsType> {
    render() {
        return (
            <div
            style={{
                display: "inline-block",
            }}>
            <a
              href={"/festival/" + this.props.id}
              style={{
                textDecoration: "none",
              }}
            > 
            <ImageListItem>
                <Typography
            variant="body2"
            color="text.secondary"
            style={{
              position: "absolute",
              margin: "0px",
              padding: "4px",

              paddingTop: "3px",
              paddingLeft: "0px",
              paddingBottom: "2px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: primaryText,
              // //제일위쪽에 배치
              zIndex: 1,

              left: "3px",
              top: "5px",

              fontSize: "0.8rem",

              // bottom: "3vw",
            }}
          >
            <Label
              content={
                <>
                  <span
                    style={{ color: "#942aff", fontSize: "0.7rem" }}
                  >
                    <b>{this.props.title.includes("전시회") ? "전시회" :
                    this.props.title.includes("카페") ? "콜라보카페" :
                    this.props.title.includes("확정") ? "소식" :
                    this.props.title.includes("극장판") ? "극장판" :
                    this.props.title.includes("펀딩") ? "펀딩" :
                    this.props.title.includes("팝업") ? "팝업스토어" :
                    this.props.title.includes("게임") ? "게임" :
                    this.props.title.includes("콜라보") ? "콜라보" :
                    this.props.title.includes("발매") ? "상품발매" :
                    this.props.title.includes("이벤트") ? "이벤트" :
                    "정보"
                    }</b>
                  </span>
                </>
              }
              color={"#ffffff"}
              border={2}
              borderColor={"#942aff"}
            />
          </Typography>
            <Card
              style={{
                    border: "2px solid " +"#ffcb68",
                padding: 0,
                margin: "2px",
                width: "30vw",
                height: "40vw",
                // height: "85vw",
                borderRadius: 2,
              }}
              sx={{
                // boxShadow: "0 0px 5px 2px rgba(255, 105, 135, 0.3)",
                display: "flex",
                // flexWrap: "wrap",
                "& > :not(style)": {
                  // m: 1,
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  //width에 맞추기
    
                  backgroundImage: `url(${this.props.compactThumbnailImgUrl})`,
                  //   backgroundSize: "contain",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  overflow: "hidden",
                  
                },
              }}
            >
              <CardActionArea> 
                <ImageListItemBar
                  style={{
                    padding: 0,
                  }}
                  sx={{
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.6) 0%, " +
                      "rgba(0,0,0,0) 100%)",
                    height: "40%",
                    margin: 0,
                    padding: 0,
                  }}
                  position="bottom"
                  title={
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                        textAlign: "left",
                        alignItems: "center",
                        whiteSpace: "pre-wrap",
                        padding: "5px",
                        lineHeight: "17px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "block !important",
                          //줄바꿈되도록하기
                          //   whiteSpace: "pre-wrap",
                          //   whiteSpace: "nowrap",
                          flex: 1,
                          color: "#f8ff82",
                          fontSize: "0.7rem",
    
                          whiteSpace: "pre-wrap",
                          textShadow: "0px 0px 8px black",
                          fontFamily:"headline1",
                        }}
                      >
                          {this.props.title}
                          {/* </font> */}
                      </div>

                      <div
                        style={{
                          width: "100%",
                          display: "block",
                          //줄바꿈되도록하기
                          whiteSpace: "pre-wrap",
                          flex: 1,
                          color: "white",
                          fontSize: "0.6rem",
                          fontFamily:"normal_kirakira_bold",
    
                          textShadow: "0px 0px 6px black",
                        }}
                      >
<div>
《 {this.props.subTitle} 》
</div>
<div>
                      기간: {this.props.startDate.substring(5,10).replace("-","/")
                      + " ~ "
                      + (this.props.endDate && this.props.endDate != undefined ? this.props.endDate.substring(5,10).replace("-","/") : "")}</div>
                      </div>
                    </div>
                  }
                />
              </CardActionArea>
            </Card>
            </ImageListItem>
            </a></div>
        );
    }
}

export default FestivalNode;