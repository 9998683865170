import React, { Component } from "react";

class HorizontalSlide extends Component<{ childrens: any; padding?: string }> {
  render() {
    return (
      <div
        className="slide-container"
        style={{
          //   margin: "7px",
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <div className="slide-item"
        style={{

          padding: (this.props.padding? this.props.padding: "5px"),
        }}
        >{this.props.childrens}</div>
      </div>
    );
  }
}

export default HorizontalSlide;
