import { get } from '@/redux/module/api';
import React, { Component } from 'react';
import FestivalList from '../atoms/FestivalList';

class Festival extends Component <{}, {resData: [] }>{

    constructor(props: {}) {
      super(props);
      this.state = {
        resData: [],
      };
    }
  
    componentDidMount = async () => {
      const res = await get("post/festival", {});
      if (res.status === 200) {
        this.setState({
          resData: res.data.resData,
        });
      }
    };
    render() {
        return (
            <FestivalList festivalList={this.state.resData} />
        );
    }
}

export default Festival;