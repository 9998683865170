import React, { Component } from "react";
import ExhibitionNode from "./ExhibitionNode";
import { ExhibitionPropsType } from "@/types/PropsTypes";
import { get } from "@/redux/module/api";

class ExhibitionList extends Component<{}, { resData: ExhibitionPropsType[] }> {
  constructor(props: any) {
    super(props);
    this.state = {
      resData: [],
    };
  }

  componentDidMount = async () => {
    try {
      const res = await get("exhibition", {});
      if (res.status === 200) {
        const data = await res.data;
        this.setState({
          resData: data.resData,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return this.state.resData && this.state.resData.length > 0 ? (
      this.state.resData.map((item: any, index: number) => {
        return (
          <ExhibitionNode
            notiCount={item.notiCount}
            readCount={item.readCount}
            wishCount={item.wishCount}
            mainItemReleaseDate={item.mainItemReleaseDate}
            isWish={item.isWish}
            isNotified={item.isNotified}
            mainProductId={item.mainProductId}
            description={item.description}
            key={index}
            id={item.id}
            title={item.title}
            content={item.content}
            image={item.image}
            color={item.color}
            startDate={item.startDate}
            endDate={item.endDate}
            animeId={item.animeId}
            categoryId={item.categoryId}
            searchKeyword={item.searchKeyword}
          />
        );
      })
    ) : (
      <div>등록된 기획전이 없습니다.</div>
    );
  }
}

export default ExhibitionList;
