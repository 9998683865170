import { ProductListPropsType } from "@/types/ProductTypes";
import { Box, IconButton } from "@mui/material";
import React, { Component } from "react";
import {
  addDot,
  isAvailableStock,
  isAvailableReservation,
  replaceSpacesAndSpecialCharacters,
} from "@/Utils/Utils";
import BoxIcon from "../atoms/BoxIcon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import {
  success,
  secondary,
  greyBoxColor,
  primaryText,
  lightGrey,
  lightYellow,
  orangePrimary,
  primary,
  primaryProductNode,
} from "@/styles/theme";
import { get } from "@/redux/module/api";

type CombinedProps = ProductListPropsType & {
  onCartClick?: any;
  key?: any;
};

export default function ProductRowListNode(props: CombinedProps) {
  const {
    id,
    name,
    animeId,
    animeName,
    isSoldOut,
    thumbnailImgUrl,
    compactThumbnailImgUrl,
    isSales,

    detailImgUrlList,
    releaseDate,
    deadlineDate,
    arrivalDate,
    originalPrice,
    reservationPrice,
    arrivalPrice,
    isWish,
    isOptionable,
    status,

    isTradingProduct,
    onCartClick,
    key,
    createTime,
    onClick,
  } = props;

  const [wish, setWish] = React.useState(isWish === 1 ? true : false);
  const [isLiked, setIsLiked] = React.useState(false);

  const [isLogin, setIsLogin] = React.useState(false);

  const onClickEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick();
    location.href =
      `/product/detail/${id}/` +
      replaceSpacesAndSpecialCharacters(animeName + "-" + name);
  };
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: "8px",
        // border: "1px solid #e0e0e0",
        //   margin: "8px", 이부분을 적용하면 오른쪽으로 튀어나감
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        className={"elementBox"}
        sx={{
          backgroundColor: "#ffffff",
          alignItems: "center",
          display: "flex",
          flex: 1,
          width: "100%",
          height: "18vw",
          border: 2,
          borderRadius: 1,
          borderColor: "divider",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
            verticalAlign: "center",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <div
            style={{
              flex: 2,
              overflow: "hidden",
            }}
            onClick={onClickEvent}
          >
            <img
              src={compactThumbnailImgUrl}
              alt=""
              style={{
                height: "18vw",
                padding: "2px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </div>
          <div
            style={{
              width: "55%",
              flex: 2,
              padding: "2px",
              lineHeight: "1.9",
            }}
            onClick={onClickEvent}
          >
            <div
              style={{
                alignItems: "center",
                display: "block",
                width: "100%",
                fontSize: "0.7rem",

                height: "100%",
                verticalAlign: "center",
                //세로 중앙 정렬
                textAlign: "left",
              }}
            >
              《{animeName}》
            </div>
            <div
              style={{
                alignItems: "center",
                display: "block",
                width: "100%",
                fontSize: "0.7rem",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",

                height: "100%",
                verticalAlign: "center",
                //세로 중앙 정렬
                textAlign: "left",
              }}
            >{name}
            </div>
            <div
              style={{
                alignItems: "center",
                display: "block",
                width: "100%",
                fontSize: "0.7rem",
                color: "blue",

                height: "100%",
                verticalAlign: "center",
                //세로 중앙 정렬
                textAlign: "right",
              }}
            >
              <b>
                {status &&
                  reservationPrice &&
                  arrivalPrice &&
                  addDot(status === 1 ? reservationPrice : arrivalPrice)}
                원
              </b>
            </div>
          </div>
          <div
            style={{
              padding: "5px",

              height: "100%",
              width: "12%",
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
              verticalAlign: "center",
            }}
          >
            {wish ? (
              <FavoriteIcon
                style={{
                  color: orangePrimary,
                  width: "22px",
                  height: "22px",
                  justifyContent: "center",
                  verticalAlign: "center",
                }}
                onClick={async (e) => {
                  try {
                    const res = await get(
                      "product/wish/" + id + "/" + (wish ? 0 : 1),
                      {}
                    );
                    console.log(res);
                    if (res.status == 200) {
                      // alert("위시리스트 등록완료");
                      setWish(!wish);
                    }
                  } catch (e: any) {
                    if (e.response.status == 401) {
                      alert("로그인이 필요합니다.");
                      location.href = "/login";
                    } else {
                      alert("위시리스트 등록실패");
                    }
                  }
                }}
              />
            ) : (
              <FavoriteBorderIcon
                style={{
                  color: orangePrimary,
                  width: "19px",
                  height: "19px",
                }}
                onClick={async (e) => {
                  try {
                    const res = await get(
                      "product/wish/" + id + "/" + (wish ? 0 : 1),
                      {}
                    );
                    console.log(res);
                    if (res.status == 200) {
                      // alert("위시리스트 등록완료");
                      setWish(!wish);
                    }
                  } catch (e: any) {
                    if (e.response.status == 401) {
                      alert("로그인이 필요합니다.");
                      location.href = "/login";
                    } else {
                      alert("위시리스트 등록실패");
                    }
                  }
                }}
              />
            )}
            {/* {((status === 2 && isAvailableStock(isSoldOut, isSales)) ||
              (status === 1 &&
                deadlineDate &&
                isAvailableReservation(
                  deadlineDate + "",
                  isSoldOut,
                  isSales
                ))) && (
              <ShoppingCartIcon
                style={{
                  marginLeft: "8px",
                  color: primaryText,
                  width: "19px",
                  height: "19px",
                }}
                onClick={(e: any) => onCartClick(id)}
              />
            )} */}
            {/* <BoxIcon
              color={primaryText}
              width="5vw"
              onClick={() => {
                alert("[좋아요] 기능은 현재 준비중입니다.");
              }}
              icon={
                <ThumbUpOffAltIcon
                  style={{
                    color: primaryText,
                    width: "18px",
                    height: "18px",
                  }}
                />
              }
              unchecked={true}
            /> */}
          </div>
        </div>
      </Box>
    </div>
  );
}
