import { ExhibitionPropsType } from "@/types/PropsTypes";
import React, { Component } from "react";
import {
  Box,
  Card,
  Chip,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  ImageListItem,
} from "@mui/material";
import { primaryText, primaryProductNode } from "@/styles/theme";
import Label from "./Label";
import { getRGBAValue } from "@/Utils/Utils";

class ExhibitionNode extends Component<ExhibitionPropsType> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {
      notiCount,
      readCount,
      id,
      title,
      content,
      image,
      animeId,
      categoryId,
      color,
      searchKeyword,
      startDate,
      endDate,
    } = this.props;
    return (
      <div
        style={{
          margin: "10px",
        }}
      >
        <Card
          sx={{
            width: "100%",
            padding: 0,
            borderRadius: "15px",
            boxShadow: "0px 0px 2px 3px " + color,

            elevation: 4,
          }}
          onClick={(e) => {
            // location.href = "/search/" + searchKeyword;
            location.href = "/exhibition/" + id;
          }}
        >
          <ImageListItem>
            <CardContent
              style={{
                width: "100%",
                // backgroundColor: color,
                //alpha값 적용
                backgroundColor: getRGBAValue(color, 0.75),
                height: "auto",
                margin: 0,
                padding: 0,
                zIndex: 1,
                bottom: 0,
                position: "absolute",
              }}
            >
              <Typography
                gutterBottom
                style={{
                  fontSize: "1rem",
                  color: "yellow",
                  padding: "7px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Label content={"기획전"} color={"#000000"} />
                <b> {title}</b>
              </Typography>
              <Typography
                variant="body2"
                color="white"
                style={{
                  padding: "6px",
                  paddingTop: "0",
                  fontSize: "0.7rem",
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                  textOverflow: "ellipsis",
                  fontFamily: "normal_kirakira",
                }}
              >
                {content}
              </Typography>
            </CardContent>
            <div
              style={{
                zIndex: 1,
                top: 0,
                right: 0,
                position: "absolute",
              }}
            >
              <Label
                content={
                  startDate.substr(0, 10) + " ~ " + endDate.substr(0, 10)
                }
                color={color}
              />
            </div>
            <CardMedia
              sx={{
                height: "45vw",
              }}
              image={image}
            />
          </ImageListItem>
        </Card>
      </div>
    );
  }
}

export default ExhibitionNode;
