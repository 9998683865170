import { Box, Paper } from "@mui/material";
import Link from "next/link";

import React, { Component } from "react";

export type ImageNodePropsType = {
  id: number;
  onClick?: any;

  title: string;
  link: string;
  subTitle?: string;
  thumbnailImgUrl: string;
  color: string;
  width: string;
};

class ImageRowListNode extends Component<ImageNodePropsType> {
  render() {
    return (
      <Box
        style={{
          padding: 0,
          margin: "3px",
          width: this.props.width,
          //width에 맞추기
          height: "60px",
          flex: 1,
        }}
        sx={{
          display: "flex",
          "& > :not(style)": {
            padding: 0,
            margin: 0,
            height: "60px",
            marginTop: "5px",
            marginBottom: "0px",
            backgroundColor: `${this.props.color}`,
            overflow: "hidden",
          },
        }}
      >
        <Paper
          style={{
            width: "100%",
            height: "auto",
            margin: 0,
            // borderRadius: "20px",
            padding: 0,
            paddingTop: "0px",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "inline-block",
            }}
            onClick={(e) => {
              location.href = this.props.link;
            }}
          >
            <div
              style={{
                float: "left",
                width: "50%",
                height: "100%",
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  textAlign: "left",

                  //텍스트 패딩
                  paddingTop: "3vw",
                  paddingLeft: "2vw",
                  // display: "flex",

                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    padding: "4px",
                    paddingLeft: 0,
                    display: "block !important",
                    //줄바꿈되도록하기import ImageNode from './ImageNode';

                    //   whiteSpace: "pre-wrap",

                    whiteSpace: "nowrap",
                    flex: 1,
                  }}
                >
                    <div
                      color="yellow"
                      className="bold_font"
                      style={{
                        textShadow: "0px 0px 3px #3a005b",
                        width: "100%",
                        fontSize: "0.8rem",
                        color: "#ffffff",

                      }}
                    >
                      {this.props.title}
                    </div>
                </div>
                {this.props.subTitle && (
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      //줄바꿈되도록하기
                      //   whiteSpace: "pre-wrap",
                      flex: 1,
                    }}
                  >
                    <div
                        className="normal_font"
                      style={{
                        color: "#ffffff",
                        width: "100%",
                        fontFamily: "subTitle",
                        //폰트사이즈 조정 *브라우저 기본 폰트크기 기준
                        // whiteSpace: "nowrap",
                        fontSize: "0.6rem",
                        whiteSpace: "pre-line", //줄바꿈되도록하기
                      }}
                    >
                      {this.props.subTitle}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                //   width: "23vw",
                //   height: "23vw",
              }}
            >
              <img
                src={this.props.thumbnailImgUrl}
                style={{
                  //%로하면 부모의 사이즈에 맞춰지기때문에 타원형이됨.
                  //   maxHeight: "100px",
                  overflowInline: "hidden",
                  overflow: "hidden",

                  padding: "-15px",

                  //이렇게하면 div바깥으로 이동가능
                  marginRight: "-3px",
                  marginTop: "-25px",

                  paddingTop: "0px",
                  borderRadius: "50%",

                  //div바깥부분은 잘라내기
                  whiteSpace: "nowrap",
                  maxWidth: "100%",

                  //   //이렇게했더니 모양이 달라짐.
                  //   paddingLeft: "100px",
                  float: "right",
                  //   right: "0px",
                }}
              />
            </div>
          </div>
        </Paper>
      </Box>
    );
  }
}

export default ImageRowListNode;
