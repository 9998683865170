import { get } from "@/redux/module/api";
import React, { Component } from "react";
import ProductRowList from "./ProductRowList";

class ProductKeywordList extends Component<{ keyword: string }, {}> {
  render() {
    return <ProductRowList
    url={"search/" + this.props.keyword} isAutoLoad={false} />;
  }
}

export default ProductKeywordList;
