import React, { Component } from "react";
import BaseComponent from "../base/BaseComponent";
import Post from "../atoms/Post";
import { get } from "@/redux/module/api";
import { getRGBAValue } from "@/Utils/Utils";
import PostList from "./PostList";

interface PostListProps {
  isLoading: boolean;
  pageNumber: number;

  top: number;
}

class PostWithSlide extends BaseComponent<{ type: string }, PostListProps> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true,
      pageNumber: 0,
      top: 0,
    };
  }

  onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.currentTarget;
  };
  componentDidMount = async () => {
    super.componentDidMount();

    // 1초마다 컴포넌트를 65px 위로 이동시키기
    this.intervalId = setInterval(() => {
      if (this.state.top >= 65 * 4) {
        this.setState({
          top: 0,
        });
      } else {
        this.setState((prevState) => ({
          top: prevState.top + 65,
        }));
      }
    }, 3000);
  };

  private intervalId?: NodeJS.Timeout;

  componentWillUnmount() {
    // 컴포넌트가 언마운트될 때 인터벌을 정리
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  render() {
    const { top } = this.state;

    return (
      <div
        style={{
          height: "65px",
        }}
      >
        <div
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            position: "relative",

            top: -top,
            transition: "top 0.5s",
          }}
        >
          <PostList type="MAIN" onClick={this.onClick} />
        </div>
      </div>
    );
  }
}

export default PostWithSlide;
